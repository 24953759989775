/* eslint-disable no-unused-vars */
import React from 'react';
import cx from 'classnames';
import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';

const GlobalPromoMessage = (props) => {
    const {
        // prettier-ignore
        storeConfig,
        // t,
    } = props;

    // animation header navigation
    const isGlobalPromoEnabled = storeConfig?.header_animation_enable === '1';
    const block_identifier = storeConfig?.header_animation_cms_block;

    const { data: dataGlobalPromo, loading: loadingGlobalPromo } = getCmsBlocks({
        identifiers: block_identifier,
    });

    if (loadingGlobalPromo && !isGlobalPromoEnabled) {
        return <></>;
    }

    if (!loadingGlobalPromo && dataGlobalPromo && dataGlobalPromo.cmsBlocks
        && dataGlobalPromo.cmsBlocks.items.length > 0 && dataGlobalPromo.cmsBlocks.items[0].content && isGlobalPromoEnabled
    ) {
        return (
            <>
                <div
                    id="global-promo-message"
                    className={cx(
                        'global-promo-message',
                        'min-h-[39px]',
                        'max-h-[79px]',
                        'text-center',
                        'font-normal',
                        'tablet:text-base',
                        'mobile:max-tablet:text-sm',
                        'bg-neutral-black',
                        'text-neutral-black',
                        'border-b',
                        'border-neutral-200',
                        'mobile:max-tablet:max-h-10',
                    )}
                >
                    {!loadingGlobalPromo && isGlobalPromoEnabled ? (
                        <div className="w-full h-10 bg-neutral-black text-neutral-white">
                            <CmsRenderer
                                content={dataGlobalPromo?.cmsBlocks?.items[0]?.content}
                                {...props}
                            />
                        </div>
                    ) : null}

                </div>
                <style jsx global>
                    {`
                        .top-header_right-link ul {
                            display: flex;
                        }
                        .top-header_right-link ul li {
                            padding: 0  20px;
                            font-size: 12px;
                            color: #242424;
                            font-weight: 500;
                            line-height: 1.4;
                        }
                        .top-header_right-link ul li a {
                            line-height: 17px;
                            letter-spacing: .3px;
                        }
                        .top-header_right-link ul li a:hover {
                            text-decoration: underline;
                        }
                        .top-header_right-link ul li:last-of-type {
                            padding-right: 0;
                            border-left: 1px solid #e3e3e3;
                        }
                    `}
                </style>
            </>
        );
    }

    return null;
};

export default GlobalPromoMessage;
